const adminRole = {
  ohbotAdmin: { name: 'ohbot 管理員', value: 'ohbotAdmin' },
  ohbotSuccessAdmin: { name: 'ohbot 成功專員', value: 'ohbotSuccessAdmin' },
  ohbotGuset: { name: '訪客', value: 'ohbotGuset' },
  orgAdmin: { name: '集團管理員', value: 'orgAdmin' },
  shopManager: { name: '店家管理員', value: 'shopManager' },
  shopMod: { name: '店家店長', value: 'shopMod' },
  shopProvider: { name: '服務人員', value: 'shopProvider' },
}

export default adminRole
